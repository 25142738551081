<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">스탬프 요청</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <div class="mb_30">
          <base-select
            :options="state"
            v-model="stateValue"
            :picked="userState.name"
          />
        </div>
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 158px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">회원아이디</td>
            <td class="box_style_02">{{ item.user.id }}</td>
            <td class="box_style_02 list_title_style">이름</td>
            <td class="box_style_02">{{ item.user.name }}</td>
            <td class="box_style_02 list_title_style">이메일</td>
            <td class="box_style_02">{{ item.email }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스템프명</td>
            <td class="box_style_02" colspan="3">{{ item.name }}</td>
            <td class="box_style_02 list_title_style">등록 일시</td>
            <td class="box_style_02">{{ item.registerDate }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스템프 주소</td>
            <td class="box_style_02" colspan="5">{{ item.address }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스템프 GPS 위도</td>
            <td class="box_style_02">{{ item.lat }}</td>
            <td class="box_style_02 list_title_style">스템프 GPS 경도</td>
            <td class="box_style_02" colspan="3">{{ item.lng }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스템프 설명</td>
            <td class="box_style_02" colspan="5" style="width: 790px">
              {{ item.content }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">스템프 이미지</td>
            <td
              v-if="cover.name"
              class="box_style_02"
              colspan="5"
              style="width: 790px"
            >
              <img
                v-if="cover"
                :src="`${cover.url}${cover.path}`"
                alt="스템프 이미지"
                style="max-width: 120px"
              />
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">처리 담당자</td>
            <td class="box_style_02" colspan="5" style="width: 790px">
              {{ item.admin }}
            </td>
          </tr>
        </table>
      </form>
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="edit">{{
          item.id ? "수정" : "등록"
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseSelect from "@/components/core/BaseSelect.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: { BaseSelect },
  props: {
    item: Object,
  },
  data() {
    return {
      id: null,
      request: {},
      tag: "",
      url: "https://",
      // file: {
      //   url: "",
      //   name: "",
      // },
      cover: {
        url: "",
        name: "",
        path: "",
      },
      selectFile: {
        picture: null,
        cover: null,
      },

      userState: { name: "", value: 0 },
      stateValue: "",
      state: [
        { name: "접수", value: 1 },
        { name: "처리중", value: 2 },
        { name: "완료", value: 3 },
        { name: "반려", value: 4 },
      ],
    };
  },
  created() {
    this.fetch();
  },
  mounted() {
    if (this.item) {
      switch (this.item.status) {
        case "접수":
          this.userState = { name: "접수", value: 1 };
          break;
        case "처리중":
          this.userState = { name: "처리중", value: 2 };
          break;
        case "완료":
          this.userState = { name: "완료", value: 3 };
          break;
        case "반려":
          this.userState = { name: "반려", value: 4 };
          break;
      }
    }
  },
  methods: {
    async fetch() {
      await client.stampRequestFindOne(this.item.id).then(
        (response) => {
          const { data } = response;
          this.tag = data.tag;
          this.url = data.url;
          // this.file.url = data.file[0].name;
          // this.file.name = data.file[0].name;

          this.cover.url = data.picture.url;
          this.cover.name = data.picture.name;
          this.cover.path = data.picture.path;
          this.request = data;

          console.log(this.request);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    async edit() {
      if (this.selectFile.picture) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.picture);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.file.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }

      if (this.selectFile.cover) {
        let form = new FormData();
        form.append("uploadFile", this.selectFile.cover);
        await client.fileAdd(form).then(
          (response) => {
            const { data } = response;
            this.cover.url = data.name;
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }

      if (this.stateValue == "접수") {
        this.stateValue = 1;
      } else if (this.stateValue == "처리중") {
        this.stateValue = 2;
      } else if (this.stateValue == "완료") {
        this.stateValue = 3;
      } else if (this.stateValue == "반려") {
        this.stateValue = 4;
      }

      let params = {
        userId: this.request.user.id,
        status: this.stateValue,
        name: this.request.name,
        content: this.request.content,
        lat: this.request.lat,
        lng: this.request.lng,
        address: this.request.address,
        picture: this.request.picture.name,
      };
      if (this.item.id) {
        await client.stampRequestModify(this.item.id, params).then(
          (response) => {
            console.log(response);
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: "정상적으로 수정되었습니다.",
              },
              {
                width: 400,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
            this.$emit("close", { refresh: true });
          },
          (error) => {
            const { data } = error.response;
            let message = data.message;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          }
        );
      }
    },
    removeFile(type) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectFile[type]) {
                // 로컬에서 선택한 파일이 있을경우
                this.selectFile[type] = null;
              }

              // if (type == "picture") {
              //   this.file.name = "";
              //   this.file.url = "";
              // }
              if (type == "cover") {
                this.cover.name = "";
                this.cover.url = "";
                this.cover.path = "";
              }
            }
          },
        }
      );
    },
    previewFile(type) {
      let selectFile = null;
      // if (type == "picture") {
      //   selectFile = this.$refs.refPicture;
      // } else
      if (type == "cover") {
        selectFile = this.$refs.refCover;
      }
      if (0 < selectFile.files.length) {
        this.selectFile[type] = selectFile.files[0];
        let fileExt = this.selectFile[type].name.substring(
          this.selectFile[type].name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectFile[type].size <= 1048576
        ) {
          // if (type == "picture") {
          //   this.file.name = this.selectFile["picture"].name;
          // } else
          if (type == "cover") {
            this.cover.name = this.selectFile["cover"].name;
          }
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectFile[type] = null;
        }
      } else {
        this.selectFile[type] = null;
      }
    },
  },
};
</script>
